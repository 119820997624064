.header-text-default {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.header-text-default .frame {
  align-items: center;
  background-color: #000000;
  border-radius: 17px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px;
  position: relative;
  transition: background-color 0.5s ease;
}

.header-text-default .about-us {
  color: #ffffff;
  font-family: "Jersey 25", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.09px;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  transition: color 0.5s ease;
}

.header-text-default .frame:hover {
  background-color: rgb(177, 227, 34);
  color: #000000;
}

.header-text-default .frame:hover .about-us {
  color: #000000;
}
